// https://theme-ui.com/theme-spec

/* Misc Notes:
  1. heading refers to h1, h2, h3, etc. Not the header/footer
  10. This file is executed once Serverside, and once Clientside
*/

/////    Brand Specific Color Settings    /////

const primaryPalette = {
  grays: {
    white: '#ffffff',
    black: '#000000',
    gray20: '#f7f7f7',
    gray30: '#dddddd',
    gray40: '#bfbfbf',
    gray50: '#868686',   // Header Nav a
    gray60: '#666666',
    gray70: '#4e4e4e',
  },
  primaries: {
    blue60: '#263645',   // footer background, h1, etc
    orange50d: '#a43716',
    orange55: '#d13a0f',
    blue50: '#025f9c',
  },
  secondaries: {
    blue40: '#3ca6e0', // links
    orange50: '#d1461c', // headerCTA a , button
    green50: '#00825b',
    blue50d: '#00416B',
  },
  tertiary: {  // Not directly used
    red50: '#a01a00',
    yellow50: '#ffb000',
    freshBlue: '#bcced8',
    blue30: '#eef6fb',
    red30: '#ffeeeb',
    green30: '#eefcf8',
  },
  bodyScents: { // Not directly used
    shiso: '#3aa072',
    stone: '#3a8fd3',
    fig: '#ef5e3e',
  },
  handleColors: { // Not directly used
    natilusBlue: '#104d6c',
    olive107: '#707c3c',
    totalOrange: '#ed7b20',
    stormGray: '#6c6864',
  },
  coreMembership: { // Not directly used
    coreGreen: '#00837b',
    coreGreenLight: '#00a390',
    coreGray: '#d7dbe2',
  },
  referenceColors: {
    text: '#4e4e4e', // grays.gray70,
    body: '#4e4e4e', // grays.gray70,
    heading: '#263645', // primaries.blue60,
    background: '#ffffff', // grays.white,
    primary:  '#d1461c', // primaries.orange50,
    secondary: '#025f9c', // primaries.blue50,
    muted: '#bfbfbf', // grays.gray40,
    navList: "#868686", // grays.gray50
    headerCTA: "#d1461c", // primaries.orange50
    link: '#3ca6e0', // accents.blue40
  }
};

// Theme config exported for access in components, build loop, and builder configs
const styles = {
  referenceColors: primaryPalette.referenceColors,
  breakpoints: ['48em', '64em', '90em'],
  space: {
    none: '0',
    small: ['0.375em', '0.375em', '0.75em'], // Arrays set responsive values from smallest breakpoint to largest
    medium: ['0.5em', '0.5em', '1em'],
    large: ['0.75em', '0.75em', '1.5em'],
    xlarge: ['1.2em', '1.5em', '3em'],
    mega: ['4em', '4em', '8em'],
  },
  sizes: {
    container: ['100%', '43.25em', '61.25em', '76.25em'],
    viewHeight100: '100vh',
    viewWidth100: '100vw',
    navHeight: '70px',
    footerHeight: 'inherit',
  },
  fonts: {
    body: '"Museo Sans", system-ui, sans-serif',
    heading: '"Brandon Grotesque", system-ui, sans-serif',
    monospace: 'Menlo, monospace',
    nav: '"Brandon Grotesque", system-ui, sans-serif',
  },
  fontSizes: [11, 13, 16, 18, 20, 24, 28, 36],
  fontWeights: {
    body: 300,
    heading: 500,
    bold: 700,
  },
  lineHeights: {
    body: 1.75,
    heading: 1.25,
    button: 1.5,
  },
  letterSpacings: {
    body: 0.5,
    heading: 0.9,
    caps: '0.125em',
    caption: '0.0625em',
  },
  // User exposed color options in the Builder UI are also drawn from this theme
  // Color must be defined here to be used in backgroundColors and other sub categories
  colors: { 
    text: primaryPalette.grays.gray70,
    body: primaryPalette.grays.gray70,
    heading: primaryPalette.primaries.blue60,
    background: primaryPalette.grays.white,
    primary: primaryPalette.secondaries.orange50,
    secondary: primaryPalette.primaries.blue50,
    muted: primaryPalette.grays.gray40,
    transparent: 'transparent',
    link: primaryPalette.secondaries.blue40,
    // brand palette
    ...primaryPalette.grays,
    ...primaryPalette.primaries,
    ...primaryPalette.secondaries,
    ...primaryPalette.accents,
    ...primaryPalette.tertiary,
    ...primaryPalette.bodyScents,
    ...primaryPalette.handleColors,
    ...primaryPalette.coreMembership,

    mediumBlue: primaryPalette.primaries.blue50,
    darkBlue: primaryPalette.primaries.blue60,
    subscriptionBlue: primaryPalette.secondaries.blue40,
    orange: primaryPalette.secondaries.orange50,
    green: primaryPalette.secondaries.green50,
    red: primaryPalette.tertiary.red30,
    yellow: primaryPalette.tertiary.yellow50,
    freshBlue: primaryPalette.tertiary.freshBlue,
  },
  // Used for component background colors
  backgroundColors: {
    transparent: 'transparent',
    
    ...primaryPalette.grays,

    mediumBlue: primaryPalette.primaries.blue50,
    darkBlue: primaryPalette.primaries.blue60,
    subscriptionBlue: primaryPalette.secondaries.blue40,
    orange: primaryPalette.secondaries.orange50,
    green: primaryPalette.secondaries.green50,
    red: primaryPalette.tertiary.red30,
    yellow: primaryPalette.tertiary.yellow,
    freshBlue: primaryPalette.tertiary.freshBlue,
  },
  radii: {
    card: ['0.25em', '0.5em'],
    button: ['0.125em', '0.25em'],
  },
  shadows: {
    card: '0 0.125em 0.1875em 0 rgba(0,0,0,0.2)',
  },
  text: {
    body: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      letterSpacing: 'body',
    },
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      letterSpacing: 'heading',
    },
  },
  // theme variants
  buttons: {
    primary: {
      bg: 'primary',
      borderRadius: 'button',
      color: 'background',
      font: 'heading',
      fontSize: 13,
      fontStyle: 'normal',
      fontWeight: 'bold',
      letterSpacing: 'caps',
      lineHeight: 'button',
      marginBottom: 'medium',
      padding: ['0.75em 3em'],
      textTransform: 'uppercase',
      transition: 'background-color .15s',
      verticalAlign: 'middle',
      whiteSpace: 'nowrap',
      '&:hover': {
        bg: 'orange50d',
      },
      '& + a': {
        marginLeft: ['none', 'none', 'small'],
      },
    },
    primaryLarge: {
      bg: 'primary',
      fontSize: 16,
      fontWeight: 'bold',
      height: '48px',
      padding: ['0.75em 3em'],
      letterSpacing: '2px',
    },
    secondary: {
      variant: 'buttons.primary',
      bg: 'secondary',
      '&:hover': {
        bg: 'blue50d',
      },
    },
    white: {
      variant: 'buttons.primary',
      bg: 'white',
      '&:hover': {
        bg: 'white',
      },
      color: 'blue60'
    },
    hollow: {
      variant: 'buttons.primary',
      bg: 'transparent',
      color: 'heading',
      border: '1px solid',
      transition: 'background-color .15s, color .15s',
      '&:hover': {
        bg: 'heading',
        color: 'background',
      },
    },
    link: {
      variant: 'buttons.primary',
      paddingLeft: 0,
      paddingRight: 0,
      bg: 'transparent',
      color: 'heading',
      textDecoration: 'underline',
      '&:hover': {
        bg: 'transparent',
      },
    },
  },
  // markdown styles
  styles: {
    root: {
      scrollBehavior: 'smooth',
      header: {
        fontSize: 1,
      },
      variant: 'text.body',
      h1: {
        variant: 'text.heading',
        fontWeight: 'bold',
        fontSize: [6, 7],
      },
      h2: {
        variant: 'text.heading',
        fontSize: [5, 6],
      },
      h3: {
        variant: 'text.heading',
        fontSize: [4, 5],
      },
      h4: {
        variant: 'text.heading',
        fontSize: [3, 4],
      },
      h5: {
        variant: 'text.heading',
        fontSize: 2,
      },
      h6: {
        variant: 'text.heading',
        fontWeight: 'bold',
        letterSpacing: 'caps',
        textTransform: 'uppercase',
        fontSize: 1,
      },
      p: {
        lineHeight: [1.4, 1.6],
        fontSize: 2,
        mb: ['medium', 'large'],
      },
      a: {
        color: 'link',
      },
      table: {
        borderCollapse: 'collapse',
        borderSpacing: 0,
      },
      tr: {
        fontSize: '1.1em',
      },
      th: {
        color: 'heading',
        padding: '0.4em',
        borderBottom: '2px solid rgba(0,0,0,0.2)',
        textTransform: 'uppercase',
        verticalAlign: 'middle',
      },
      td: {
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        padding: '0.4em',
        lineHeight: '1.8em',
        verticalAlign: 'middle',
        '& p': {
          fontSize: '80%',
          margin: '0.4em 0',
          lineHeight: '1.3',
        },
      },
    },
    textStyles: ['default', 'light', 'dark', 'quote'],
    textColors: ['default', 'white', 'mediumBlue', 'darkBlue', 'subscriptionBlue', 'orange', 'green', 'red', 'yellow', 'freshBlue'],
    default: {
      h1: { color: 'heading' },
      h2: { color: 'heading' },
      h3: { color: 'heading' },
      h4: { color: 'heading' },
      h5: { color: 'heading' },
      h6: { color: 'heading' },
    },
    // TODO: Colors should be set using textColors moving forward. 
    light: {
      h1: { color: 'white' },
      h2: { color: 'white' },
      h3: { color: 'white' },
      h4: { color: 'white' },
      h5: { color: 'white' },
      h6: { color: 'white' },
      p: { color: 'white' },
      a: { color: 'white' },
      ul: { color: 'white' },
      ol: { color: 'white' },
      li: { color: 'white' },
    },
    dark: {
      h1: { color: 'heading' },
      h2: { color: 'heading' },
      h3: { color: 'heading' },
      h4: { color: 'heading' },
      h5: { color: 'heading' },
      h6: { color: 'heading' },
      p: { color: 'heading' },
      ul: { color: 'heading' },
      ol: { color: 'heading' },
      li: { color: 'heading' },
    },
    quote: {
      borderLeft: 'solid 0.15rem #185674',
      paddingLeft: '20px',
      color: '#185674',
      lineHeight: '1.5',
      fontStyle: 'italic',
    }
  },
};
export default styles;